@import '../../Constants';

td.v3-td {
  border-top: 1px solid $GeyserGray;
  padding-right: 10px;
  word-wrap: break-word;
  @include v3-text-colors;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.no-padding {
    padding: 0px 0px;
  }

  .is-key-criteria {
    margin-left: 5px;
  }
}

table.sortable {
  td.v3-td.right {
    padding-right: 20px;
  }

  .v3-tbody tr.v3-tr.hoverable td:last-child {
    &.right {
      padding-right: 30px;
    }
  }
}
