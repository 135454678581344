/* Vars */
@import "./assets/styles/_vars.scss";

/* Base Styles */
@import "./assets/styles/_fonts.scss";
@import "./assets/styles/_base.scss";
@import "./assets/styles/_buttons.scss";
@import "./assets/styles/_toggle.scss";

/* Component styles */
@import "./assets/styles/_header.scss";
@import "./assets/styles/_page.scss";
@import "./assets/styles/_sideDrawer.scss";
@import "./assets/styles/_sidebar.scss";
@import "./assets/styles/_findingSidebar.scss";
@import "./assets/styles/_toast.scss";
@import "./assets/styles/_input.scss";
@import "./assets/styles/_semantic-transition.scss";
@import "./assets/styles/_semantic-loader.scss";
@import "./assets/styles/_semantic-pagination.scss";

/* Page specific styles */
@import "./assets/styles/_dashboard.scss";
@import "./assets/styles/_unauth-container.scss";