.vulnerability-details_container {
  .vulnerability-details_row {
    border-top: 1px solid #f2f2f2;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;

    

    .cve-id {
      width: 30%;
    }

    .cvss-score {
      width: 20%;
    }

    .cvss-rating {
      width: 20%;
    }

    .cvss-date {
      width: 20%;
    }

    .more-details {
      width: 10%;
    }

    svg {
      margin-left: 10px;
    }

    .closed svg {
      transform: rotate(90deg);
      transition-duration: .5s;
    }

    .expanded svg {
      transform: rotate(-90deg);
      transition-duration: .5s;
    }
  }
  .vulnerability-expanded_summary {
    margin-bottom: 20px;
  }
  .vulnerability-expanded_links {
    margin-bottom: 20px;
    font-weight: 200;
    font-size: 13px;
  }

  .vulnerability-expanded {
    padding: 20px;

    .vulnerability-expanded_link {
      line-height: 23.1px;
    }
  }
}