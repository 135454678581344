@import './constants';

html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: normal;
  color: $text-color;
  min-width: 1140px;
  overflow-x: auto;
  background: #fafafa;
}

.no-scroll {
  overflow-y: hidden !important;
}

h1 {
  font-size: 2.6em;
  font-weight: 200;
}

h2 {
  font-size: 1.8em;
  font-weight: 200;
}

h3 {
  font-size: 1.6em;
  font-weight: 200;
}

a {
  color: $link-color;
}

.text-red {
  color: $riskrecon-red;
}
.red-text {
  color: $riskrecon-red;
}
.text-blue {
  color: $riskrecon-blue;
}
.blue-text {
  color: $riskrecon-blue;
}
.text-yellow {
  color: $riskrecon-yellow;
}
.yellow-text {
  color: $riskrecon-yellow;
}
.green-text {
  color: $riskrecon-green;
}
.text-green {
  color: $riskrecon-green;
}
.semi-bold {
  font-weight: 600;
}
.centered {
  text-align: center;
}

.full-width {
  width: 100%;
}

/* clearfix */
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.flex {
  display: flex;
}

.flex.horizontal {
  flex-direction: row;
}

.flex.vertical {
  flex-direction: column;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-flex-end {
  justify-content: flex-end;
}

.flex.justify-space-between {
  justify-content: space-between;
}

.flex.align-center {
  align-items: center;
}

.content-container {
  width: 1125px;
  margin: 0 auto;

  &.mfa-settings-container {
    width: 1140px;
    min-height: 70vh;
  }
}

.rr-pagination.pagination.ui.menu {
  height: 29px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  display: inline-flex;
  vertical-align: middle;

  a.item {
    position: relative;
    font-size: 13px !important;
    color: #b0b0b0 !important;
    min-width: 0px;
    padding: 6px 15px;
    cursor: pointer;

    &.active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &::before {
      position: absolute;
      content: ' ';
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: rgba(34, 36, 38, 0.1);
      border-radius: 0.28571429rem;
    }
  }
}

.rr-card {
  background: #fff;
  padding: 20px 20px 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.rr-table {
  width: 100%;
  font-size: 0.95em;
  border-collapse: collapse;

  th {
    color: $NileBlue;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-transform: capitalize;
    text-align: center;
    padding: 5px 10px;

    svg {
      margin-left: 5px;
    }

    svg.desc {
      transform: rotate(180deg);
      transition-duration: 0.5s;
    }

    svg.asc {
      transform: rotate(0deg);
      transition-duration: 0.5s;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
  td,
  th {
    // box-shadow: 0 1px 0 0 $border inset;
    border-bottom: 1px solid $border;
  }

  tbody {
    tr {
      &:hover {
        background-color: #fafafa;
      }
    }

    td {
      padding: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-of-type {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
      }

      &.clickable {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .priority,
  .requests,
  .days_open {
    text-align: right;
  }
}

.no-scroll {
  overflow: hidden;
}

#portal-footer {
  text-align: center;
  margin: 50px 0;
}
.clickable {
  cursor: pointer;
}

button.reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
