@import '../../../shared/Constants';

.asset-value-section {
  margin-bottom: 20px;

  .asset-value-metric-container {
    display: flex;
    margin-top: 20px;

    .v3-metric-item {

      margin-right: 45px;

      .metric-value p {
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
}
.metric-label {
  display: flex;
  font-size: 12px;
  color: $FiordBlue;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.additional-context-help-icon {
  margin-left: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  fill: $GeyserGray;
  &:hover {
    fill: $RoyalBlue;
  }
}
