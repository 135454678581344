#issues-row_container:first-child {
    margin-top: 10px;

  .issues_criteria-row {
    margin-top: 0px;
  }
}

#issues-row_container {
  border-bottom: 1px solid #f2f2f2;
  padding: 0px 6px;

  .issues_criteria-row {
    font-size: 14px;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .criteria-row_more-details {
    margin-left: 20px;
    font-size: 13px;

    svg {
      margin-left: 5px;
    }

  }

  .closed svg {
    transform: rotate(90deg);
    transition-duration: .5s;
  }

  .expanded svg {
    transform: rotate(-90deg);
    transition-duration: .5s;
  }

  .threat-intell-table_container {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  table.threat-intell-issues-table {
    thead th {
      text-transform: capitalize;
      font-weight: 300;
      font-size: 14px;
      background: none;
      border-left: none;
      cursor: pointer;
      padding-bottom: 6px;
    }

    td {
      font-size: 13px;
      word-wrap:break-word;
      font-weight: 300;
      line-height: 19.2px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  
  .pagination-wrapper {
    margin-bottom: 20px;
  }
}