@font-face {
  font-family: 'Open Sans';
  font-weight: 200;
  src: url("../fonts/opensans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local("Open Sans"), url("../fonts/opensans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: local("Open Sans"), url("../fonts/opensans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url("../fonts/opensans/OpenSans-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: url("../fonts/opensans/OpenSans-Bold.ttf") format("truetype");
}



// MC Banner fonts

@font-face {
  font-family: 'Mark Offc For MC';
  font-weight: 700;
  src: url("../fonts/markoffc/MarkForMCNrwO-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Mark Offc For MC';
  font-weight: 500;
  src: url("../fonts/markoffc/MarkForMCNrwO-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Mark Offc For MC';
  font-weight: 450;
  src: url("../fonts/markoffc/MarkForMCNrwO-Light.ttf") format("truetype");
}



