@import "../../../shared/Constants";

.customer-request {
  margin-top: 20px;
  .table {
    margin-top: 20px;
    .header {
      border-bottom: 1px solid $GeyserGray;
      display: flex;
      line-height: 24px;
      .column {
        color: $NileBlue;
        font-size: 12px;
        font-weight: 700;
      }
      .name-column {
        width: 40%;
      }
      .date-column,
      .due-column,
      .comment-column {
        width: 12%;
      }
      .action-plan-column {
        width: 24%;
      }
    }
  }
}
