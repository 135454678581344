.widget-card-button-box-svgs {
  display: flex;
  width: 110px;
  position: relative;
  left: 45%;
  top: 290px;
  z-index: 200;

  .decayed {
    fill: transparent;
    stroke-opacity: 50%;
    stroke: rgb(255, 255, 255);
    stroke-width: 1px;
  }

  .fresh {
    fill: transparent;
    stroke-opacity: 100%;
    stroke: rgb(255, 255, 255);
    stroke-width: 1px;
  }

  .active {
    fill: transparent;
    stroke-opacity: 100%;
    stroke: rgb(255, 255, 255);
    stroke-width: 1px;
    animation: clock-animation 12s linear infinite;
    stroke-dasharray: 32;
    animation-direction: reverse;
    transform: rotate(-90deg);
    transform-origin: center;
  }

  @keyframes clock-animation {
    0% {
      stroke-dashoffset: 32;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
