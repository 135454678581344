@import "../../shared/Constants";

.issue-description {
  margin-bottom: 20px;

  .metric-container {
    display: flex;
    margin-top: 4px;
    width: 100%;
    flex-wrap: wrap;

    .v3-metric-item {
      width: 50%;
      margin-top: 16px;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .issue-text {
    margin-top: 1rem;
    .metric-value {
      font-size: 14px;
      font-weight: 200;
      color: #173a56;
    }
  }
  .issue-description {
    margin-top: 1rem;
      .metric-value {
        font-size: 14px;
        font-weight: 200;
        color: #173a56;
      }
    }
  }

.how-to-fix-container {
  margin-bottom: 20px;
  margin-top: 20px;

  .how-to-fix-content {
    display: flex;
    flex-direction: column;

    .support-site-container,
    .who-should-fix-container,
    .how-to-fix-it-container {
      margin-top: 20px;
      flex: 1 1 auto;

      .how-to-fix-label {
        color: $FiordBlue;
        margin-bottom: 3px;
      }

      .finding-details-value {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 200;
        color: #173a56;

        &.capitalize {
          text-transform: capitalize;
        }

        .href-wrapper {
          color: $RoyalBlue;
          a{
            text-decoration: none;
            color: $RoyalBlue;
          }
        }
      }

      &:first-child {
        margin-top: 10px;
      }
    }
  }
}
