.toast{
  position: fixed;
  text-align: left;
  top: 50px;
  right: 50px;
  min-width: 320px;
  max-width: 500px;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
  border-radius: 4px;
  background-color: #fff;
  z-index: 10000;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  &.toast--error:before {
    background-color: $riskrecon-red
  }
  &.toast--success:before {
    background-color: $riskrecon-green
  }
  &.toast--warning:before {
    background-color: $riskrecon-yellow
  }
  &.toast--info:before {
    background-color: $riskrecon-blue
  }

  .toast__content {
    padding: 10px 10px 10px 20px;
    width: 85%;
  }

  .toast__kind {
    font-size: 14px;
    color: black;
    font-weight: 500px;
    text-transform: capitalize;
    margin: 0;
  }

  .toast__list {
    padding-left: 18px;
  }
  .toast__message {
    font-weight: 200px;
    font-size: 12px;
    margin: 10px 0 0 0;
  }

  .toast__close {
    position: absolute;
    top: 10%;
    width: 14px;
    height: 14px;
    right: 20px;
    cursor: pointer;
  }

}