.dimension-list_container {
  width: 100%;
  .dimension-details_headers {
    display: flex;
    font-size: 14px;
    color: #969696;
    padding: 0 10px 6px;

    .dimension_header {
      width: 50%;
    }

    .dimension-rating_header {
      width: 50%;
    }
  } 
}
