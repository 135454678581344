@import '../constants';

.dashboard-widget-cards {
  display: flex;
  margin: 30px 0 20px;

  .widget-card-issues {
    width: 361px;
  }
  .widget-card-summary {
    position: relative;
    width: 749px;
  }

  .trendline {
    width: 1125px;
  }

  .dashboard-rating-card {
    display: flex;
    width: 100%;
  }

  .widget-summary-stats-row {
    .customer-name-span {
      width: 450px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .customer-issue-span {
      text-align: right;
    }
    .customer-slider-span {
      display: flex;
      justify-content: center;
      width: 100px;
    }
    display: flex;
    justify-content: space-between;
    span {
      height: 24px;
      margin-bottom: 5px;
    }
    &.heading {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .widget-card {
    margin-right: 20px;
    position: relative;

    .widget-card--title {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;

      svg {
        margin-left: 6px;
        fill: $GeyserGray;

        &:hover {
          fill: $RoyalBlue;
        }
      }

      .widget-card-tooltip {
        display: flex !important;
        align-items: center;
      }
    }

    .widget-count {
      font-size: 36px;
      font-weight: 600;
    }
    .widget-subheading {
      font-size: 16px;
      font-weight: 200;
    }
    .widget-divider {
      border-bottom: 1px solid $border;
      margin: 10px 0;
      width: 100%;
    }
    .widget-stats-wrapper {
      .widget-stats-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        border-top: 1px solid $GeyserGray;
        padding: 8px 0;

        &:last-child {
          border-bottom: 1px solid $GeyserGray;
        }

        .show-more {
          color: $riskrecon-blue;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.heading {
          font-weight: 600;
          font-size: 16px;
          border: none;
        }

        .fixed-row {
          display: flex;
          align-items: center;
          div {
            display: flex;
            align-items: center;
            border-radius: 6px;
            svg {
              margin-right: 6px;
            }
          }
          .fixed-not-resolved {
            background: #ecbfc4;
            padding: 4px 8px;
            margin-right: 11px;
          }

          .fixed-pending-scan {
            padding: 4px 8px;
            background: $GeyserGray;
            margin-right: 11px;
          }

          .fixed-resolved {
            padding: 4px 8px;
            background: rgba(39, 174, 96, 0.25);
            margin-right: 11px;
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
