@import '../../assets/styles/constants';

div.vp-container {
  background-color: $White;
  border: 1px solid $GeyserGray;
  border-radius: 10px;
  padding: 24px 30px;
  position: relative;

  &.box-shadow {
    box-shadow: $LargeBoxShadow;
  }

  &.medium {
    border-radius: 10px;
  }
}
