.dashboard-domain-card {
    margin-bottom: 20px;
    h2 {
      margin: 0;
    }

    .btn--download-domain-report {
      float: right;
    }

    .domain-summary-wrapper {
      display: flex;
      justify-content: space-between;

      div {
        width: 48%;

        .summary-heading {
          color: $text-color-light;
          margin: 30px 0 10px;
        }
      }
    }
  }