@import "../../assets/styles/_constants.scss";

.token-expired-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .token-expired-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 32px;

    img {
      height: 100%;
      width: 100%;
    }
  
  }

  .token-expired-content-right {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 16px 0;

    h1 {
      font-family: Open Sans;
      font-size: 36px !important;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: -0.04em;
      color: $FiordBlue;
    }

    p {
      color: $FiordBlue;
      font-family: Open Sans, sans-serif;
      line-height: 25px;
      font-size: 16px;
    }

    .token-expired-content-right-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        border: 1px solid $RoyalBlue;
        border-radius: 4px;
        padding: 12px 16px;
        color: $RoyalBlue;
        margin-right: 16px;
        text-decoration: none;
      }
    }
  }

}