.v3-container.host-summary {
  margin-bottom: 20px;
  margin-top: 20px;

  .metric-container {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 15px;

    .host-summary-metric-item {
      margin-top: 15px;
      margin-right: 50px;
    }
  }

}
