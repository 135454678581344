$font: 'Open Sans', Calibri, Arial, sans-serif;

$primary-color: #6bc048;
$riskrecon-green: $primary-color;
$riskrecon-blue: #70a9d6;
$riskrecon-red: #e31d1a;
$riskrecon-yellow: #fad000;
$border: #d6d6d6;
$text-color: #173a56;
$text-color-light: #969696;
$link-color: $riskrecon-blue;
$disabled-bg: #e8e8e8;
$disabled-text: #5f6368;

$PeriwinkleGray: #b5cde3;
$NileBlue: #173a56;
$DeepNavyBlue: #0e2435;
$White: #ffffff;
$Black: #000000;
$GeyserGray: #d5dde4;

$LargeBoxShadow: 0px 8px 18px rgba(14, 36, 53, 0.08);
