@import '../../../shared/Constants';

.update-status {
  margin-top: 20px;
  .label-text {
    font-weight: 600;
    font-size: 12px;
    color: $NileBlue !important;
  }
  .issue-status-card{
    margin-top: 20px !important;
  }
}
