@import './_configure.scss';

#phone-number-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .v3-text-input {
    width: 38%;
  }
  .country-code {
    width: 38%;
    margin-bottom: 30px;
    label {
      display: block;
      font-size: 12px;
      padding: 8px 0px;
      font-weight: 600;
    }
  }
  button {
    width: 20%;
    margin-top: 5px;
  }
}

#verify-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dummy-box {
    width: 38%;
  }
  .v3-text-input {
    width: 38%;
  }
  button {
    width: 20%;
    margin-top: 5px;
  }
}
