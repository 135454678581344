@import './constants';

#page-dashboard {
  .no-customers-wrapper {
    display: flex;
    background-color: #fef9e7;
    padding: 12px 16px;
    color: #867432;
    align-items: center;
    margin-top: 20px;

    svg {
      margin-right: 15px;
    }
  }

  .filter-findings-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .filter-findings-buttons {
      display: flex;
      button {
        height: 42px;
        background: $AthensGray;
        padding: 13px 16px;
      }
      button.active {
        color: $NileBlue;
        background: $White;
        font-weight: 700;
      }
      button:first-child {
        border: 1px solid $GeyserGray;
        border-radius: 4px 0 0 4px;
        border-right: none;
      }
      button:last-child {
        border: 1px solid $GeyserGray;
        border-radius: 0 4px 4px 0;
      }
    }
    .filter-findings-wrapper {
      width: 450px;
    }
  }

  .stats-status {
    text-transform: capitalize;
  }

  @import './Dashboard/BannerCard.scss';
  @import './Dashboard/WidgetCard.scss';
  @import './Dashboard/DomainCard.scss';
  @import './Dashboard/DomainFindingsTable.scss';
  @import './Dashboard/DomainFindingsRow.scss';
  @import './Dashboard/BannerCard.scss';
}
