.event-log-container {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  .event-log-body {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-top: 1px solid #D5DDE4;

    > p {
      margin-top: 10px;
    }
  }
}
