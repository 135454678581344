@import '../../../../assets/styles/constants';

span.vulnerability-info-link {
  color: $RoyalBlue;
  &:hover {
    text-decoration: underline;
  }
}

.vulnerability-expanded-details-container {
  margin: 20px 0;

  h4 {
    margin-bottom: 10px;
  }

  a {
    display: block;
    color: $RoyalBlue;
    line-height: 22px;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }
  }
}
