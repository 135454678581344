@import "../../../shared/Constants";

.customer-request-details {
  display: flex;
  border-bottom: 1px solid $GeyserGray;
  line-height: 32px;
  .customer_name-column {
    width: 40%;
  }
  .due_date-column,
  .overdue-column,
  .comment-column {
    width: 12%;
  }
  .found_in_current_action_plan-column {
    width: 24%;
  }
}
