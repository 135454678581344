@import '../../assets/styles/constants';

// copied from here - https://codepen.io/manabox/pen/raQmpL
input.radio:checked,
input.radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

input.radio:checked + label,
input.radio:not(:checked) + label {
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

input.radio:checked + label {
  font-weight: 600;
}

input.radio:checked + label:before,
input.radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid $GeyserGray;
  border-radius: 100%;
  background: #fff;
}

input.radio:checked + label:after,
input.radio:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: $RoyalBlue;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input.radio:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

input.radio:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

input.radio:disabled + label {
  cursor: not-allowed;
}
