@import '../../Constants';

.v3-view-details-td {
  padding-right: 10px;
  font-weight: 200;
  color: $RoyalBlue;
  .view-details-content-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .view-details-content {
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 15px;

      .view-details-text {
        margin-right: 10px;
      }
      svg.details-chevron-svg {
        fill: $RoyalBlue;
        transition: transform .2s linear;
        transform: rotate(180deg);
        &.expanded {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.v3-tbody tr.v3-tr.accordion-parent.hoverable {
  &:hover {
    .v3-view-details-td {
      font-weight: 200;
      color: $RoyalBlue;
      .view-details-content {
        background-color: $AthensGray;
        border-radius: 4px;
      }
    }
  }

  &.expanded,
  &.expanded:hover {
    .v3-view-details-td {
      font-weight: 200;
      .view-details-content {
        background-color: transparent;
      }
    }
  }
}
