@import '../assets/styles/constants';

div.nav-item {
  cursor: default;
  float: right;
  margin: 25px 0;
  list-style-type: none;
  font-weight: 600;
}

.nav-item {
  display: flex;
  position: relative;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 0 10px;
  outline: none;
  svg {
    position: relative;
    top: 2px;
    &.spin {
      transition: 0.2s linear;
      transform: rotate(180deg);
    }
  }
  &:hover,
  &.active {
    // &:hover, &.active, &:focus-within {
    //background: $DianneBlue;
    //color: $White;
    //fill: $White;

    svg {
      transform: rotate(0deg);
    }

    .sub-menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
      max-height: 90vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
      background: #fff;
      border: 1px solid #ddd;
      color: #4f4f51;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;

      .hover-nav {
        padding: 12px 10px 12px 10px;
        background-color: #fff;
        min-width: 140px;
        cursor: pointer;
        color: #4f4f51;
        text-decoration: none;
        &:hover {
          background: rgba(213, 221, 228, 0.5);
        }
      }

      button {
        border: none;
        padding: 0 0 12px 0;
        text-align: left;
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  .sub-menu {
    top: 100%;
    left: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.15s ease;
    z-index: 999;
  }
}
