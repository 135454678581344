.vulnerabilities-list_container.container {
  .vulnerability-details_header {
    padding: 6px 0;
    display: flex;
    font-size: 14px;
    color: #969696;

    .cve-id_header {
      width: 30%;
    }

    .cvss-score_header {
      width: 20%;
    }

    .cvss-rating_header {
      width: 20%;
    }

    .cvss-date_header {
      widows: 20%;
    }

  }
  .pagination-wrapper {
    margin-top: 25px;
  }
}