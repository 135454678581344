
#finding-sidebar-loader-wrapper {
  .ui.active.transition.visible.inverted.dimmer {
    background: none;
  }
}

.collaboration-sidebar-container,
.finding-details-sidebar-container {
  width: 820px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;

  .finding-details-top-section {
    text-align: left;
    padding: 40px 75px 35px 75px;
    box-sizing: border-box;
    background: #FFF;

    .criteria-header {
      font-size: 28px;
      font-weight: 600;
      margin: 0 0 5px;
      color: $NileBlue;
    }

    .issue-title {
      font-size: 16px;
      margin: 0;
      font-weight: 600;
      color: $FiordBlue;
    }

    .issue-resolved-span {
      margin-bottom: 12px;
      padding: 4px 8px;
      background: rgba(39, 174, 96, 0.25);
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #173A56;
    }
  }

  .sidebar-top-nav {
    min-height: 38px;
    flex: 0 1 38px;

    .top-nav-left, .top-nav-right {
      border-bottom: 1px solid $border;
    }

    .top-nav-left {
      width: 30px;
    }

    .top-nav-right {
      flex-grow: 1;
    }

    .top-nav-right.no-host {
      width: 580px;
    }

    .top-nav-option {
      width: 110px;
      font-weight: 600;
      font-size: 14px;
      height: 38px;
      border-bottom: 1px solid $border;
      box-sizing: border-box;
      cursor: pointer;
    }

    .selected-true {
      background: #F7F7F7;
      border-bottom: none;
      border-right: 1px solid $border;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
    }
  }

  .sidebar-sub-nav {
    background: #FFF;
    position: relative;
    padding-left: 60px;
    box-shadow: 0px 8px 24px -8px rgba(10, 27, 38, 0.08);
    display: flex;
    min-height: 39px;
    flex: 0 1 39px;

    .sub-nav-option {
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 110px;
      padding: 0 10px;
      box-sizing: border-box;

      p {
        font-weight: 400;
        font-size: 12px;
        margin: 0;
        color: $FiordBlue;
      }
    }

    .sub-nav-option.selected-true {
      border-bottom: 2px solid $RoyalBlue;

      p {
        font-weight: 700;
        font-size: 12px;
        color: $NileBlue;
      }
    }

    .selected-bar {
      position: absolute;
      width: 100px;
      height: 5px;
      background: $primary-color;
      left: 100px;
      top: 32px;
      transition-property: all;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .selected-bar.right {
      left: 398px;
    }
  }

  .finding-details-bottom-section {
    padding: 0 75px 20px 75px;
    overflow-y: scroll;
    flex: 1 1 auto;
    background-color: #FAFAFA;

    .issue-details-pane-container {
      min-height: 500px;
    }
  }

  p.finding-details-header {
    font-size: 16px;
    color: #4F4F51;
    text-align: left;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .rr-card.issue-status-card,
  .rr-card.finding-details-card {
    margin-top: 0px;
    padding: 10px 20px;
  }

  .row-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;

    .label-value-wrapper {
      width: 50%;
    }
  }

  .row-wrapper.unset {
    justify-content: unset;

  }

  .label-value-wrapper.third {
    width: 33%;
  }

  .label-value-wrapper.half {
    width: 50%;
  }

  .label-value-wrapper.full {
    width: 100%;
  }

  .href-wrapper {
    word-break: break-all;
  }

  .row-wrapper:last-child {
    margin-bottom: 0px;
  }

  .finding-details-label {
    font-size: 14px;
    color: #969696;
    line-height: 22px;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0;
  }

  .finding-details-value {
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 10px 0;

    &.capitalize {
      text-transform: capitalize;
    }
  }

  .sidebar-breadcrumbs {
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 13px;

    .go-back {
      position: relative;
      color: #979797;
      cursor: pointer;

      .arrow {
        position: absolute;
        top: 3px;
        left: -20px;
      }
    }
  }

  .issue-mgmt-link {
    color: $riskrecon-blue;
    margin-bottom: 10px;
    cursor: pointer;
  }


}
