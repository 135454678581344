@import "../../../shared/Constants";

.event-log-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $GeyserGray;
  height: 83px;
  flex-direction: row;
  .status-indicator {
    width: 5px;
    height: 100%;
    background-color: $PeriwinkleGray;
  }
  .content {
    width: calc(100% - 10px);
    height: 100%;
    display: inline-block;
    margin-left: 15px;
    .log-item-date {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      color: $FiordBlue;
      line-height: 20px;
      margin-top: 16px;
      justify-content: flex-end;
    }

    .log-item-message {
      display: flex;
      color: $NileBlue;
      font-size: 16px;
      // font-weight: 600;
      letter-spacing: -0.02em;
      line-height: 24px;
    }
  }
}
