.event-log-wrapper {
  max-height: 400px;
  overflow: auto;
  position: relative;
  
  .event-log-item {
    margin-top: 20px;
    word-wrap: break-word;

    &:nth-of-type(1) {
      margin-top: 0;
    }
    .log-item__date {
      font-size: 12px;
      color: $text-color-light;
    }
    .log-item__message {
      margin-top: 2px;
    }
  }
}
