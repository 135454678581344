@import '../shared/_Constants.scss';

.v3-line-chart {
  position: relative;
  svg {
    .tick-label text {
      fill: $FiordBlue;
    }
  }
  .info-only-text {
    font-size: 16px;
    letter-spacing: -0.02em;
    font-weight: 600;
  }

  .no-analyses-text {
    font-size: 16px;
    letter-spacing: -0.02em;
    fill: $NileBlue;
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    height: 26px;

    .time-filter-wrapper {
      display: flex;
      margin-left: 30px;

      .time-filter-nav {
        display: flex;

        div {
          font-size: 10px;
          font-weight: 600;
          margin: 0 10px;
          cursor: pointer;

          &.active {
            position: relative;
            font-weight: bold;

            &:before {
              position: absolute;
              content: '';
              bottom: 2px;
              left: -25%;
              width: 150%;
              height: 3px;
              background: $RoyalBlue;
            }
          }
        }
      }

      .data-loss-key-description {
        position: relative;
        display: flex;
        color: $NileBlue;
        font-size: 10px;
        font-weight: 600;
        margin-left: 55px;
        svg {
          margin: 0 4px;
        }
      }
    }

    .active-item-wrapper {
      display: flex;

      .active-item-figure {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .active-item-label {
          margin-right: 10px;
          font-size: 10px;
          color: $FiordBlue;
        }
        .active-date {
          font-size: 14px;
          font-weight: 600;
          color: $NileBlue;
        }
      }
    }
  }
}
