#content-container {
  margin: 0 auto;
  width: 1140px;
  min-height: calc(100vh - 125px);
  &.added-margin {
    margin-top: 83px;
  }
  &.v3-added-margin {
    margin-top: 305px;
  }
  &.v3-added-margin-report {
    margin-top: 233px;
  }
  &.sub-sub-nav-present {
    margin-top: 310px;
  }
}
