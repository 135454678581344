@import '../Constants';

div.v3-container {
  background-color: $White;
  border: 1px solid $GeyserGray;
  border-radius: 8px;
  padding: 24px 30px;
  position: relative;

  &.box-shadow {
    box-shadow: $LargeBoxShadow;
  }

  &.medium {
    border-radius: 8px;
  }
}
