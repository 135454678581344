@import './assets/styles/constants';

.eula-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  margin-top: 30px;
  background-color: white;
  border: 1px solid rgba(87, 117, 146, 0.25);
  border-radius: 10px;
  padding: 32px 136px;

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol li ol li {
    margin: 1em 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
  }

  ol li > p {
    margin: 1em 0;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li:before {
    content: counters(item, '.') '. ';
    padding-right: 1em;
  }

  table {
    width: 90%;
    border: 1px solid;
    border-top: none;
    border-right: none;
    border-spacing: 0;
    margin: 10px 0;

    td {
      border-top: 1px solid;
      border-right: 1px solid;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
    }
  }

  a {
    color: $RoyalBlue;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 14px;
    color: $NileBlue;
  }

  .eula-subtext-container {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $FiordBlue;
      padding: 0 8px;
    }
    span:first-child {
      border-right: 1px solid $FiordBlue;
    }
  }

  .eula-content {
    border-top: 1px dashed rgba(95, 124, 151, 0.5);
    padding: 32px 0;
    width: 100%;
    margin-top: 34px;

    .eula-content-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.03em;
        color: $NileBlue;
      }

      button {
        border: none;
        background-color: transparent;
        color: $FiordBlue;
        padding: 0 16px;
      }

      button:first-child {
        border-right: 1px solid $FiordBlue;
        color: $FiordBlue;
      }
    }

    div.eula-wrapper {
      margin-top: 20px;
      line-height: 20px;

      p {
        margin: 10px 0;
      }

      ol {
        counter-reset: item;
        margin: 10px 0;
      }
      li {
        display: block;
        margin: 10px 0;
      }
      li:before {
        content: ' ' counters(item, '.') '. ';
        counter-increment: item;
        margin-left: -30px;
      }

      table {
        width: 90%;
        border: 1px solid;
        border-top: none;
        border-right: none;
        border-spacing: 0;
        margin: 10px 0;

        td {
          border-top: 1px solid;
          border-right: 1px solid;
          padding: 5px;
          text-align: center;
          vertical-align: middle;
        }
      }

      .eula-faq-table {
        width: 100%;
        border: none;

        .eula-faq-table-body {
          .eula-table-row {
            width: 100%;
            td {
              border-top: 1px solid #d5dde4;
              border-right: none;
              height: 74px;
              padding: 16px 0;

              p:last-child {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: $FiordBlue;
                text-align: left;
              }

              p:first-child {
                border-bottom: 1px solid #d5dde4;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $NileBlue;
              }

              a {
                color: $RoyalBlue;
              }
            }

            .eula-table-row-heading {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              cursor: pointer;
              margin-bottom: 0;
            }

            .eula-table-heading-button {
              border: none;
              background-color: white;

              svg {
                height: 18px;
                width: 18px;
              }
            }

            ul {
              li {
                color: $FiordBlue;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
          .eula-table-row:last-child {
            td {
              border-bottom: 1px solid #d5dde4;
            }
          }
        }
      }
    }
  }
}
