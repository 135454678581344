input[type="text"], input[type="password"] {
    padding-left: 10px;
  }

.form-group {
  margin-top: 10px;
  width: 100%;
  .form-header{
    display: flex;
  }
  label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    color: $text-color-light
  }
  .form-comment{
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    margin-left: auto;
    color: $text-color;
  }

  input {
    display: block;
    height: 38px;
    border-radius: 4px;
    border: 1px solid $border;
    box-shadow: inset 0px 1px 2px rgba(102, 113, 123, 0.21);
    width: 100%;
    font-size: 14px;
    margin-top: 7px;
    font-family: $font;
    color: $text-color;

    &::placeholder {
      font-family: $font;
    }

    &.is-error {
      border: 1px solid $riskrecon-red;
    }
  }

  textarea {
    display: block;
    border-radius: 4px;
    border: 1px solid $border;
    box-shadow: inset 0px 1px 2px rgba(102, 113, 123, 0.21);
    width: 100%;
    height: 100px;
    overflow: scroll;
    font-size: 14px;
    margin-top: 7px;
    padding-left: 10px;
    padding-top: 5px;
    overflow: auto;
    font-family: $font;
    color: $text-color;

    &::placeholder {
      font-family: $font;
    }

    &.is-error {
      border: 1px solid $riskrecon-red;
    }
  }
}

.form-input-hint {
  &.is-error {
    font-size: 10px;
    color: $riskrecon-red;
  }
}