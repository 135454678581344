@import '../Constants';

.v3-score-change {
  display: flex;
  align-items: center;
  font-weight: 600;

  &.negative {
    color: $BrightRed;
    fill: $BrightRed;
    svg {
      transform: rotate(45deg);
    }
  }
  &.positive {
    color: $JewelGreen;
    fill: $JewelGreen;
    svg {
      transform: rotate(-45deg);
    }
  }
  &.steady {
    color: $GeyserGray;
    fill: $GeyserGray;
  }
}
