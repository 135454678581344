@import '../Constants';

.v3-header {
  color: $NileBlue;
  margin: 0;
  font-weight: bold;

  &.light {
    color: $FiordBlue;
  }
  &.semibold {
    font-weight: 600;
  }
  &.regular {
    font-weight: normal;
  }
}

h1.v3-header {
  font-size: 18px;
  letter-spacing: -0.02em;
}
h2.v3-header {
  font-size: 16px;
  letter-spacing: -0.02em;
}
h3.v3-header {
  font-size: 14px;
}
h4.v3-header {
  font-size: 12px;
}
h5.v3-header {
  font-size: 10px;
}
