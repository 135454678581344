/*--------------
   Pagination
---------------*/
.rr-pagination.pagination.ui.menu {
  margin-top: 20px;
  height: 30px;

  a.item {
    font-size: 13px !important;
    color: #b0b0b0 !important;
    min-width: 0px;
  }
}

.rr-pagination__small.pagination.ui.menu {
  min-height: 15px;

  a.item {
    font-size: 13px !important;
    color: #b0b0b0 !important;
    min-width: 0px;
    padding: 7px 10px;
  }
}

.ui.pagination.menu {
  margin: 0em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.ui.pagination.menu .item:last-child {
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}
.ui.compact.menu .item:last-child {
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}
.ui.pagination.menu .item:last-child:before {
  display: none;
}
.ui.pagination.menu .item {
  min-width: 3em;
  text-align: center;
}
.ui.pagination.menu .icon.item i.icon {
  vertical-align: top;
}

/* Active */
.ui.pagination.menu .active.item {
  border-top: none;
  padding-top: 0.92857143em;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  -webkit-box-shadow: none;
          box-shadow: none;
}