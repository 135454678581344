@import '../../assets/styles/constants';

#portal-header {
  background: #fff;
  box-shadow: 0 8px 24px rgba(10, 27, 38, 0.08);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9996;

  .horizontal-divider-filter {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $GeyserGray;
    position: absolute;
    top: 170px;
  }

  .horizontal-divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $GeyserGray;
    position: absolute;
    top: 70px;
  }

  .content-container {
    display: flex;
    flex-direction: column;

    .filter-findings-container {
      display: flex;
      justify-content: space-between;
      background: #fff;
      z-index: 1;
      padding: 20px 0;
      .filter-findings-buttons {
        display: flex;
        button {
          height: 42px;
          background: $AthensGray;
          padding: 13px 16px;
        }
        button.active {
          color: $NileBlue;
          background: $White;
          font-weight: 700;
        }
        button:first-child {
          border: 1px solid $GeyserGray;
          border-radius: 4px 0 0 4px;
          border-right: none;
        }
        button:last-child {
          border: 1px solid $GeyserGray;
          border-radius: 0 4px 4px 0;
        }
      }
      .filter-findings-wrapper {
        width: 450px;
      }

      &.hide {
        display: none;
      }
    }

    .dashboard-heading {
      display: flex;
      justify-content: space-between;

      .dashboard-heading__right {
        display: flex;
        align-items: center;

        .dashboard-heading__icon {
          font-size: 24px;
          margin-right: 10px;
          color: #f6c200;
        }

        .support-text {
          width: 196px;
          height: 38px;
          font-size: 14px;
          line-height: 18px;
          color: $text-color-light;
          display: flex;
          align-items: center;
          transition: all 0.3s ease;

          &:hover {
            color: $text-color;
          }

          #request-tooltip {
            color: #ffffff !important;
            background-color: #333333 !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
            width: 259px;
            padding: 12px 20px;
          }
        }

        .seperator__verticle {
          height: 38px;
          border-right: 1px solid #d8d8d8;
          margin: 0 30px;
        }

        .btn--download-full-report {
          display: flex;
          height: 39px;
          padding: 0 10px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .logo {
    float: left;
    height:32px;
    padding-top: 19px;
  }

  nav {
    #site-nav {
      float: left;
      margin: 10px 0;
      padding: 0 0 0 30px;
      list-style-type: none;
      border-left: 1px solid $border;
      height: 50px;
      line-height: 50px;

      li {
        a {
          padding: 5px;
          color: $text-color;
          font-size: 1.15rem;
          text-decoration: none;
          border-bottom: 2px solid $riskrecon-green;
        }
      }
    }
  }
}
