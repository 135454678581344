
.side-drawer-container {

  .side-drawer-overlay {
    position: fixed;
    left: 0;
    top: 0;
    background: #4A4A4A;
    opacity: .65;
    width: 100vw;
    height: 100vh;
    z-index: 9997;
  }

  .side-drawer-wrapper {
    position: fixed;
    right: 0px;
    width: 820px;
    z-index: 9998;
    background: #F8F8F8;
    top: 0px;
    height: 100%;
    border-left: 1px solid $border;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    .close-sidebar {
      font-size: 22px;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }
}

.side-drawer-container.open-false {

  .side-drawer-wrapper  {
    width: 0px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    .close-sidebar {
      display: none;
    }
  }

  .side-drawer-overlay {
    display: none;
  }
}

#drawer-button {
  position: fixed;
  width: 50px;
  left: 50px;
  top: 100px;
}
