@import '../../assets/styles/constants';

.v3-pagination-container {
  display: flex;
  align-items: center;
  color: $FiordBlue;
  margin-top: 22px;

  .item {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    border-radius: 13px;
    height: 26px;
    &.selected {
      color: $White;
      background-color: $NileBlue;
    }
    &.ellipsis {
      padding: 0;
    }
  }

  button {
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }

  button.next-previous {
    padding: 0 5px;

    svg {
      fill: $BurntSienna;
    }
    &:disabled svg {
      opacity: 0.5;
    }
  }
}
