@import '../assets/styles/constants';

.eula-modal {
  width: 510px;
  height: 325px;
  font-family: Open Sans, sans-serif;

  a {
    color: $RoyalBlue;
  }

  .eula-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 44px;
    width: 100%;

    .validation-error-text {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      color: $BrightRed;
      margin-bottom: 24px;
    }
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: $NileBlue;
    margin-bottom: 14px;
  }

  span {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $FiordBlue;
  }

  .eula-accept-label {
    display: flex;
    margin: 33px 0 24px 0;

    &.validation-error {
      .checkbox-wrapper {
        label {
          border: 1px solid $BrightRed;
        }
      }
    }

    span {
      margin-left: 15px;
    }
  }

  .eula-continue-button {
    padding: 13px 25px;
    background: white !important;
    border: 1px solid $JewelGreen !important;
    color: $JewelGreen !important;
    border-radius: 4px !important;

    &:hover {
      background: $JewelGreen !important;
      color: white !important;
    }

    &.disabled {
      border: 1px solid $Silver !important;
      color: $Silver !important;
      background: white !important;
    }
  }
}
