@import '../../../shared/Constants';

.v3-container.dimensions-section {
  margin-bottom: 20px;

  .dimensions-table {
    margin-top: 20px;
  }

  .dimension-rating {
    &.pass {
      color: $JungleGreen;
    }
    &.fail {
      color: $BrightRed;
    }
    &.info {
      color: $RoyalBlue;
    }
  }
}


.dimension-details-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .dimension-details {

    &:last-child {
      width: 235px;
    }

    .finding-details-label {
      font-size: 12px;
      margin-bottom: 7px;
    }
    .finding-details-value {
      font-size: 12px;
      word-break: break-word;
      margin-bottom: 0;
    }
  }
}
