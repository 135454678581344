@import '../../assets/styles/constants';
@import '../../assets/styles/hacks';

.v3-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  z-index: 1;

  .loading-text {
    margin-top: 20px;
  }
  &.small .loading-text {
    font-size: 12px;
  }
  &.medium .loading-text {
    font-size: 14px;
  }
  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &.with-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $WhiteOverlay;
      backdrop-filter: blur(5px);
    }

    @include only_ie10_above(
      '.overlay',
      (
        background-color: rgba(255, 255, 255, 0.95),
      )
    );
  }
}

.v3-refresh-loading {
  display: flex;
  align-items: center;
  color: $FiordBlue;

  span {
    margin-left: 7px;
    font-size: 10px;
  }

  svg.loading-spinner {
    fill: $FiordBlue;
  }
}

svg.loading-spinner {
  animation: rotate-loader 0.8s linear infinite;
}

@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.v3-card > .v3-loading,
.v3-container > .v3-loading {
  .overlay {
    border-radius: 10px;
  }
}

.v3-container.medium > .v3-loading {
  .overlay {
    border-radius: 8px;
  }
}
