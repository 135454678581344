@import '../../shared/Constants';

button.v3-borderless-button {
  font-size: 14px;
  letter-spacing: -0.02em;
  // height: 24px;
  line-height: 24px;
  display: flex;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &.loading {
    cursor: default;

    svg:only-child {
      margin: 0;
    }
    &:focus {
      outline: none;
    }
  }

  &.green {
    color: $JewelGreen;
    &:hover, &.loading {
      color: $BottleGreen;
      text-decoration: underline;
      svg { fill: $BottleGreen; }
    }
    svg { fill: $JewelGreen; }
  }
  &.red {
    color: $BrightRed;
    &:hover, &.loading {
      color: $Scarlett;
      text-decoration: underline;
      svg { fill: $Scarlett; }
    }
    svg { fill: $BrightRed; }
  }
  &.blue {
    color: $RoyalBlue;
    &:hover, &.loading {
      color: $ToryBlue;
      text-decoration: underline;
      svg { fill: $ToryBlue; }
    }
    svg { fill: $RoyalBlue; }
  }
  &.dark-blue {
    color: $NileBlue;
    &:hover, &.loading {
      color: $DeepNavyBlue;
      text-decoration: underline;
      svg { fill: $DeepNavyBlue; }
    }
    svg { fill: $NileBlue; }
  }
  &.dark {
    color: $DeepNavyBlue;
    &:hover, &.loading {
      color: $FireFly;
      text-decoration: underline;
      svg { fill: $FireFly; }
    }
    svg { fill: $DeepNavyBlue; }
  }
  &:disabled {
    color: $Silver;
    svg { fill: $Silver; }
    cursor: default;
    &:hover, &.loading {
      text-decoration: none;
      color: $Silver;
      font-weight: 400;
      svg { fill: $Silver; }
    }
  }
}