.sidebar-error-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  h2 {
    margin-bottom: 5px;
  }

  .retry-button {
    width: 145px;
    margin-top: 10px;
    background-color: transparent;
  }
}