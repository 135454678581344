@import '../../shared/Constants';

button.v3-select-button {
  font-size: 14px;
  height: 44px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  border: 1px solid;
  background-color: none;
  box-shadow: $SmallBoxShadow;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.15s ease-in;
  background-color: transparent;

  & svg:only-child {
    margin: 0 -10px;
  }

  & :not(:first-child) {
    margin-left: 5px;
  }

  &.green {
    border-color: $JewelGreen;
    color: $JewelGreen;
    fill: $JewelGreen;
    &:hover {
      background-color: $JewelGreenA05;
    }
    &:disabled {
      border-color: $JewelGreenA25;
      color: $JewelGreenA25;
      background-color: transparent;
    }
    &.selected {
      color: $White;
      fill: $White;
      background-color: $JewelGreen;
    }
  }
  &.red {
    border-color: $BrightRed;
    color: $BrightRed;
    fill: $BrightRed;
    &:hover {
      background-color: $BrightRedA05;
    }
    &:disabled {
      border-color: $BrightRedA25;
      color: $BrightRedA25;
      background-color: transparent;
    }
    &.selected {
      color: $White;
      fill: $White;
      background-color: $BrightRed;
    }
  }
  &.blue {
    border-color: $RoyalBlue;
    color: $RoyalBlue;
    fill: $RoyalBlue;
    &:hover {
      background-color: $RoyalBlueA05;
    }
    &:disabled {
      border-color: $RoyalBlueA25;
      color: $RoyalBlueA25;
      background-color: transparent;
    }
    &.selected {
      color: $White;
      fill: $White;
      background-color: $RoyalBlue;
    }
  }
  &.dark-blue {
    border-color: $NileBlue;
    color: $NileBlue;
    fill: $NileBlue;
    &:hover {
      background-color: $NileBlueA05;
    }
    &:disabled {
      border-color: $NileBlueA25;
      color: $NileBlueA25;
      background-color: transparent;
    }
    &.selected {
      color: $White;
      fill: $White;
      background-color: $NileBlue;
    }
  }
  &:disabled {
    cursor: default;
  }
}
