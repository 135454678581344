.widget-card-banner-rr-banner {
  border-radius: 10px;
  height: 278px;
  h1 {
    position: relative;
    left: 30px;
    top: 30px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.02em;
    color: #ffffff;
  }
  p {
    position: relative;
    left: 30px;
    top: 16px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #ffffff;
  }
  .rr-button {
    position: relative;
    width: 155px;
    height: 44px;
    left: 30px;
    top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    label {
      position: relative;
      height: 22px;
      left: 1px;
      right: 78.68%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #0e2435;
      cursor: pointer;
    }
  }
}
