@import '../../assets/styles/constants';

.v3-modal {
  position: relative;
  z-index: 1010;
  display: flex;
  background-color: $White;
  border: 1px solid $GeyserGray;
  border-radius: 10px;
  box-shadow: $LargeBoxShadow;
  width: 784px;
  height: 526px;
  top: 80px;
  max-height: calc(95% - 80px);
  max-width: 95%;

  .modal-close-button {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
    color: $FontColor;
    border-color: $FontColor;
    fill: $FontColor;
    outline: none;
    z-index: 2;
  }
  .modal-close-arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 25px;
    top: 20px;
    color: $FontColor;
    border-color: $FontColor;
    fill: $FontColor;
    outline: none;
    z-index: 2;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 6px;
  }
}

.v3-modal-overlay {
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $DeepNavyBlueA60;
}

.hide-overlay {
  background-color: transparent;
}
