@import '../../assets/styles/constants';

$btn-border: #979797;
.btn {
  display: inline-block;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 18px;
  font-family: $font;
  font-size: 1em;
  font-weight: 200;
  border: 1px solid $btn-border;
  background-color: #fff;

  &.btn--blue {
    background-color: $RoyalBlue;
    color: #fff;
    border: 1px solid $RoyalBlue;
  }

  &.btn--green {
    background-color: $riskrecon-green;
    color: #fff;
    border: 1px solid $riskrecon-green;
  }

  &.btn--red {
    background-color: $riskrecon-red;
    color: #fff;
    border: 1px solid $riskrecon-red;
  }

  &.btn--blue-outline {
    background-color: #fff;
    color: $RoyalBlue;
    border: 1px solid $RoyalBlue;
  }

  &.btn--green-outline {
    background-color: #fff;
    color: $riskrecon-green;
    border: 1px solid $riskrecon-green;
  }

  &.btn--red-outline {
    background-color: #fff;
    color: $riskrecon-red;
    border: 1px solid $riskrecon-red;
    transition: all 0.3s ease;

    &:hover {
      background-color: $riskrecon-red;
      color: #fff;
    }
  }

  &:disabled {
    border-color: $disabled-bg;
    background-color: $disabled-bg;
    color: $disabled-text;
    cursor: default;
  }

  &.btn--pdf {
    position: relative;
    padding-left: 55px;
    font-weight: 600;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &.generating-pdf:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, start) 900ms infinite;
      animation: ellipsis steps(4, start) 900ms infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
