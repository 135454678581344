@import './_configure.scss';

#configure-tbt-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  #enter-verification-token {
    width: 277px;
  }
  button {
    width: 168px;
    margin-top: 5px;
    margin-left: 15px;
  }
}

#tbt-qr-code {
  position: absolute;
  top: 18px;
  left: -18px;
  height: 200px;
  width: 200px;
}

.configure-steps {
  display: flex;
  flex: 1;
  margin-bottom: 70px;
  .step-block {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    strong {
      font-weight: bold;
    }
    .loading-spinner {
      margin: auto auto;
    }
    p {
      margin-top: 0;
      margin: 0 0 1em;
      line-height: 1.4285em;
    }
  }
  .step-image-container {
    display: flex;
  }
  .step-line {
    align-items: center;
    margin-left: 24px;
  }
  .step3-line {
    position: absolute;
    left: -12px;
    margin-top: 88px;
  }
}
