@import '../../assets/styles/constants';

#multi-factor-verification {
  overflow: hidden;
  margin: 30px 0;
  background: #fff;
  background-color: #fff;
  border: 1px solid $GeyserGray;
  border-radius: 10px;

  p {
    margin: 0 0 1em;
    line-height: 1.4285em;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }

  .heading {
    border-bottom: 1px solid $GeyserGray;
    padding: 23px;
    display: flex;
    justify-content: space-between;
    .mfa-header {
      display: flex;
      flex-direction: column;
      position: relative;
      color: $NileBlue;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .mfa-disabled-tooltip {
        position: absolute;
        left: 272px;
        top: 3px;
        fill: $GeyserGray;
        &:hover {
          fill: $RoyalBlue;
        }
      }
      .sub-title {
        font-size: 16px;
        font-weight: 400;
        color: $FiordBlue;
        flex-direction: row;
      }
      &.disable {
        color: $Silver;

        .sub-title {
          color: $Silver;
        }
      }
    }
    .mfa-toggle {
      display: flex;
      flex-direction: row;

      #toggle-mfa-checkbox {
        &:disabled > .tgl-btn {
          cursor: not-allowed;
        }
      }

      .mfa-toggle-label {
        margin-right: 13px;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;

        .status-label {
          padding-right: 5px;
          color: $NileBlue;
          margin-bottom: 0;
        }

        .status-value {
          font-weight: 600;
          &.toggle-enabled {
            color: $RoyalBlue;
          }
          &.toggle-disabled {
            color: $Silver;
          }
        }
      }

      .tgl + .tgl-btn {
        height: 1.4em;
        width: 2.7em;
        margin-top: 2px;
      }
    }
  }
  .selections {
    display: flex;
    flex-direction: row;
    .left-side {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-right: 1px solid $GeyserGray;
      width: 300px;
      .radio-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 26px;
        .sub-text {
          font-size: 12px;
          margin-left: 28px;
          font-weight: 200;
        }
        .configured {
          display: flex;
          align-items: center;
          margin-left: 28px;
          margin-top: 8px;
          svg {
            width: 12px;
            height: 12px;
            fill: $RoyalBlue;
          }
          p {
            font-size: 10px;
            font-weight: 200;
            color: $RoyalBlue;
            margin-left: 3px;
          }
        }
      }
      .bottom-text {
        color: $Silver;
        font-size: 12px;
        margin-top: auto;
      }
      &.disable {
        color: $Silver;
      }
    }
    .right-side {
      min-height: 300px;
      margin-bottom: 30px;
      margin-right: 30px;
      padding: 30px;
      width: 100%;
    }
  }
}
