@import '../Constants';
.v3-rating-gauge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  span {
    margin-left: 5px;
  }
  &.f {
    svg circle.active-ring {
      stroke: $BrightRed;
    }
  }
  &.d {
    svg circle.active-ring {
      stroke: $BurntOrange;
    }
  }
  &.c {
    svg circle.active-ring {
      stroke: $CreamCanYellow;
    }
  }
  &.b {
    svg circle.active-ring {
      stroke: $RoyalBlue;
    }
  }
  &.a {
    svg circle.active-ring {
      stroke: $JewelGreen;
    }
  }
  &.na {
    fill: $GeyserGray;
  }
  svg {
    transform: rotate(90deg);
    & circle {
      fill: transparent;
      stroke-width: 1.5;
      // stroke-linecap: round;
      &.base-ring {
        stroke: $PeriwinkleGrayA25;
      }
      &.active-ring {
        transition: stroke-dashoffset 1s linear;
      }
    }
    g.tick-gaps {
      line {
        stroke-width: 1.5;
        // stroke: $White;
      }
    }
    g.pointer {
      circle {
        fill: $DeepNavyBlue;
        // stroke: $White;
        stroke-width: 0.75;
        transition: transform 1s linear;
      }
    }
    text {
      fill: $DeepNavyBlue;
      &.rating-value {
        font-size: 13px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
      }
      &.rating-type {
        font-size: 4.3px;
        letter-spacing: -0.02em;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
