.host-issues-tab-pane-container {
  .table-loader {
    min-height: 500px;
  }

  .no-findings {
    text-align: center;
    margin-top: 40px;
    font-style: italic;
  }

  #sidebar-table {
    border: none;
    
    th {
      text-transform: capitalize;
      font-weight: 300;
      font-size: 14px;
      background: none;
      border-left: none;
      padding: 5px 10px 6px;
      white-space: nowrap;
    }

    td {
      font-size: 13px;
      word-wrap: break-word;
      font-weight: 400;
      line-height: 19.2px;
      padding: 5px 10px;
      position: relative;

      &.finding-priority {
        width: 10%;
        text-align: right;
      }

      &.finding-severity {
        width: 15%;
      }

      &.finding-detail {
        width: 50%;
      }

      &.finding-issue {
        width: 25%;
      }

      .host-issue-row {
        text-transform: uppercase;
      }

      .finding-priority {
        text-align: right;
      }
    }
  }
}
