.dimension-details_container {
  .dimension-details_row {
    border-top: 1px solid #f2f2f2;
    padding: 7px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    &.clickable {
      cursor: pointer;
    }
    .dimension {
      width: 50%;
    }
    .rating {
      width: 45%;

      &.pass {
        color: #6BBF50;
      }
      &.fail {
        color: #e53535;
      }
      &.info {
        color: #6FA9D8;
      }
      div {
        margin-left: 10px;
      }
    }
    .more-details {
      width: 5%;
    }
    svg {
      margin-left: 10px;
    }
    .closed svg {
      transform: rotate(90deg);
      transition-duration: .5s;
    }
    .expanded svg {
      transform: rotate(-90deg);
      transition-duration: .5s;
    }
  }
  .dimension-expanded {
    padding: 10px;
    h4 {
      margin-top: 20px;
    }
    .dimension-expanded_link {
      line-height: 23.1px;
    }

    .finding-details-value {
      margin-bottom: 5px;
    }
  }
}