.host-issues-pane-table-container {
  margin-bottom: 20px;

  .host-issues-pane-table {
    border-collapse: collapse;
    
    .v3-tr {
      height: 37px;
    }

    .v3-thead {
      .v3-tr {
        .v3-th {
          color: #173a56;
        }
      }
    }

    .v3-tbody {
      .v3-tr {
        .v3-td {
          font-size: 14px;
          font-weight: 200;
        }
        :nth-child(1),
        :nth-child(2) {
          color: #173a56;
        }
      }
    }
  }
}
