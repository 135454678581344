.issue-management-description {
  margin-bottom: 20px;

  .metric-container {
    display: flex;
    margin-top: 20px;

    .v3-metric-item {
      margin-right: 45px;
      
      &::first-letter {
        text-transform: capitalize;
      }
    }
    .v3-metric-item:nth-child(1){
      .metric-value{
        word-break: break-all;
      }
    }
  }
}
