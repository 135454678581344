@import '../Constants';
@import "../../../../src/assets/styles/vars.scss";

.v3-metric-item {
  display: flex;
  flex-direction: column;

  .metric-label {
    font-size: 12px;
    color: $FiordBlue;
    margin-bottom: 7px;
    text-transform: capitalize;
  }
  .metric-value {
    font-size: 24px;
    font-weight: 600;
    color: $NileBlue;
    letter-spacing: -0.02em;

    &.na {
      color: $GeyserGray;
      text-transform: uppercase;
    }

    &.word-break {
      word-break: break-all;
    }

    @include v3-text-colors;
  }

  &.small {
    .metric-label {
      margin-bottom: 3px;
    }
    .metric-value {
      font-size: 16px;
    }
  }
  .text-red {
    color: $riskrecon-red;
  }
  .red-text {
    color: $riskrecon-red;
  }
  .text-blue {
    color: $riskrecon-blue;
  }
  .blue-text {
    color: $riskrecon-blue;
  }
  .text-yellow {
    color: $riskrecon-yellow;
  }
  .yellow-text {
    color: $riskrecon-yellow;
  }
  .green-text {
    color: $riskrecon-green;
  }
  .text-green {
    color: $riskrecon-green;
  }
  .semi-bold {
    font-weight: 600;
  }
  .centered {
    text-align: center;
  }
}
