.customer-requests__table {
  .customer-requests__headers {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid $border;
    color: $text-color-light;
  }

  .customer-requests__body {

  }

  .customer_name__column {
    width: 25%;
  }
  .due_date__column {
    width: 20%;
  }
  .overdue__column {
    width: 15%;
  }
  .comment__column {
    width: 25%;
  }
  .found_in_current_action_plan__column {
    width: 20%;
    text-transform: capitalize;
  }
  .action_plan_column {
    width: 25%;
  }
  .show-details {
    width: 5%;
    text-align: center;
    color: $riskrecon-blue;

    &.expanded {
      svg {
        transform: rotate(-90deg);
      }
    }
    svg {
      transform: rotate(90deg);
      transition-duration: .5s;
    }
  }
}
