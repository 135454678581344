@import '../../../assets/styles/constants';

.mfa-screen {
  width: 507px;
  margin: 42px auto;
  h1,
  h3,
  p {
    text-align: center;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .enter-code-text {
    font-size: 14px;
    text-align: center;
    padding: 0px 70px;
    span {
      cursor: pointer;
      color: #2f80ed;
      padding: 0px 5px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    padding: 36px 70px;
    .v3-text-input,
    .v3-select {
      margin: 0px 0px 24px 0px;
    }
    .enter-code-group {
      display: flex;
      justify-content: space-between;
      .v3-text-input {
        display: flex;
        margin-right: 15px;
        flex: 1;
      }
    }
    button[type='submit'] {
      align-self: center;
      margin: 10px;
      width: 130px;
    }
  }
  .would-you-like {
    font-size: 12px;
    text-align: center;
    padding: 15px 0px;
    border-bottom: 1px solid lightgrey;
  }
  .sign-out {
    text-align: center;
    .v3-button {
    }
    a {
      font-size: 12px;
      color: $RoyalBlue;
      font-weight: 600;
    }
  }
}
