
.host-profile-geolocation {
  margin-bottom: 20px;

  .geolocation-metrics {
    display: flex;
    margin-top: 20px;

    .v3-metric-item {
      margin-right: 60px;
    }
  }
}
