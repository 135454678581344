@import '../constants';

.notInCustomerPlan {
  background: #f9fafb;
  cursor: not-allowed;

  td {
    color: #828282 !important;
    cursor: not-allowed;
    div {
      color: #828282 !important;
      cursor: not-allowed;
    }
  }
}

tr.domain-findings-row {
  cursor: pointer;

  td {
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    div {
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    div.host-name {
      color: #2f80ed;
    }

    div.cell-value {
      div {
        display: flex !important;
        align-items: center !important;
      }
    }
  }

  td.reporting_status {
    max-width: 150px;
    div {
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      svg {
        margin-right: 8px;
        height: 20px;
        width: 20px;
      }
    }
  }

  td.fixed_resolved {
    color: $RoyalBlue;
  }

  td.fixed_not_resolved {
    color: $BrightRed;
  }

  td.asset_value {
    padding: 0 15px;
  }

  .asset_value {
    text-transform: capitalize;
  }
  .severity {
    text-transform: capitalize;
  }
  .your_status {
    text-transform: capitalize;
  }

  .finding_extra_data_value,
  .finding_data_value,
  .example_domain,
  .host_name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .domain-findings-icon-td {
    width: 20px;
  }

  .domain-findings-row-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      fill: #2f80ed;
    }
  }
}
