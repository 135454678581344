.domain-table-wrapper {
  position: relative;
  overflow: hidden;

  .domain-table {
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #435A70;
    width: 100%;

    th {
      white-space: nowrap;
      text-align: left;
      &.right {
        text-align: right !important;
      }
    }
  }

  &.loading {
    min-height: 400px;
  }

  .pagination-wrapper {
    padding-bottom: 10px;
    margin-bottom: -10px;
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 999;
  }
}
