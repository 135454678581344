.v3-score-change-td {

  &.left {
    .v3-score-change {
      justify-content: flex-start;
    }
  }

  &.right {
    .v3-score-change {
      justify-content: flex-end;
    }
  }

  &.center {
    .v3-score-change {
      justify-content: center;
    }
  }
}