@import '../../../shared/Constants';

.issue-status-container {
  display: flex;
  margin-bottom: 20px;

  .v3-header {
    margin-bottom: 20px;
  }

  .issue-status {
    flex: 1;
    display: flex;
    flex-direction: column;

    .issue-status-metric-items-container {
      display: flex;

      .top-metric-item {
        margin-bottom: 30px;
      }

      .metric-item-left-col {
        display: flex;
        flex-direction: column;
        width: 150px;
        margin-right: 20px;

      }
      .metric-item-right-col {
        display: flex;
        flex-direction: column;
        .fix-date-label {
          margin-bottom: 3px;
          font-size: 12px;
          color: $FiordBlue;
        }
        .v3-date-picker {
          width: 173px;
        }
      }
    }
  }
  .issue-status-divider {
    margin: 45px 30px 0 30px;
    border-left: 1px solid $GeyserGray;
  }
  .issue-actions {
    display: flex;
    flex-direction: column;

    .v3-button {
      width: 125px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .v3-button:hover{
      background-color: unset !important;
      color: #B30015 !important;
    }
  }
}
