.customer-requests__row {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid $border;
  
  &:hover {
    background-color: #fafafa;
  }
}

.comment-details__wrapper {
    padding: 20px 0;
    word-wrap: break-word;

    .comment-details__label {
      color: $text-color-light;
      margin-bottom: 5px;
    }

    .comment-details__value {
      &.no-comment {
        font-style: italic;
      }
    }
  }