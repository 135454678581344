.issue-collaboration-pane-container {
  padding: 20px 0;

  .rr-form-input {
    font-family: Open Sans,Calibri,Tahoma,Arial,sans-serif;
  }

  .rr-form-select {
    font-family: Open Sans,Calibri,Tahoma,Arial,sans-serif;
  }

  .select-here {
    input {
      border-color: red
    }
  }
  
  .status-row {
    display: flex;
    margin-bottom: 20px;

    .form-group {
      margin-top: 0;
    }

    .status-item {
      width: 50%;



      label {
        color: $text-color-light;
        margin-bottom: 5px;
        display: flex;
        height: 21px;
      }
      .status-tooltip {
        margin-left: 3px;
        cursor: pointer;
      }
      .react-datepicker-wrapper {
        width: 100%;
      }

      .react-datepicker__input-container {
        input {
          display: block;
          border-radius: 4px;
          border: 1px solid $border;
          box-shadow: inset 0px 1px 2px rgba(102, 113, 123, 0.21);
          width: 100%;
          height: 34px;
          font-size: 14px;
          color: $text-color;
        }
      }

      &.date-input {
        padding-left: 20px;
      }
      &.full-width {
        width: 100%;
      }
    }
  }
  .message-wrapper {
    margin-top: 20px;

    .rr-form-input {
      width: 100%;
      resize: none;
    }
  }
  .submit-consent{
    font-size: 10px;
    letter-spacing: -.01em;
    line-height: 16px;
    color: $text-color;
  }
  
  .action-plan-submit-wrapper,
  .submit-wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .cancel-button {
      display: block;
      color: #969696;
      cursor: pointer;
      margin-right: 20px;
      padding: 8px;
    }

    .submit-response-button {
      width: 100px;
      height: 40px;
    }

    .left {
      float: left;
      width: 300px;
    }

    .right {
      float: right;
      width: 300px;
    }
  }
}