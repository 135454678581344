@import '../../assets/styles/constants';

.configured-screen {
  width: 100%;
  height: 100%;
  background-color: rgba(39, 174, 96, 0.1);
  padding: 15px;
  border-radius: 2px;
  p {
    font-weight: 600;
    font-size: 14px;
    color: $JewelGreen;
  }
}
