.vulnerability-details_container {
  .vulnerability-details_row {
    border-top: 1px solid #f2f2f2;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    .cve-id {
      width: 35%;
    }
    .cvss-score {
      width: 20%;
      div {
        margin-left: 10px;
      }
    }
    .cvss-rating {
      width: 25%;
      div {
        margin-left: 10px;
      }
    }
    .more-details {
      width: 20%;
    }
    svg {
      margin-left: 10px;
    }
    .closed svg {
      transform: rotate(90deg);
      transition-duration: .5s;
    }
    .expanded svg {
      transform: rotate(-90deg);
      transition-duration: .5s;
    }
  }
  .vulnerability-expanded {
    padding: 20px;
    h4 {
      margin-top: 20px;
    }
    .vulnerability-expanded_link {
      line-height: 23.1px;
    }
  }
}
